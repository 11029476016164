import { graphql, Link } from "gatsby"
import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout/layout"
import Title from "../../components/Title/Title"
import Text from "../../components/Text/Text"
import Console from "../../components/Console/Console"
import TxtImg from "../../components/TxtImg/TxtImg"
import Button from "../../components/Button/Button"
import { StaticImage } from "gatsby-plugin-image"

function LensTypes({ data }) {
    return (
        <Layout pageTitle="Contact Lenses">
            <Helmet>
                <meta name="description" content="Wearing contact lenses makes them a big part of life so it's important to make sure you're wearing what's best for you. Find the independent advice you need to help you make the right choice." />
            </Helmet>
            <section>
                <Title>
                    <h1>Contact Lenses</h1>
                    <p>Wearing contact lenses makes them a big part of life so it's important to make sure you're wearing what's best for you.</p>
                    <StaticImage src="../../images/trial-frame.png" alt="frames illustration" width="250" />
                </Title>
            </section>
            <section className="condense">
                <Text>
                    <h2 id="lens-check">Contact lens check</h2>
                    <h3>Vision testing and prescription refinement</h3>
                    <p>As with a full comprehensive eye examination, the letter chart will be used to check your vision in combination with the trial frame and glass lenses to assess whether any adjustments need to be made to your prescription.</p>
                    <h3>Check of contact lenses</h3>
                    <p>A detailed inspection of the lens will be carried out in situ using the slit lamp biomicroscope to check that it is fitting well with your eyes and tear film. Specialist instrumentation will also be used to check the conditions of the lenses out of the eye.</p>
                    <h3>Eye health</h3>
                    <p>A detailed examination will be conducted to look at the front of the eye where the contact lens sits. This is to ensure that there are no signs of damage or any other issues caused by the lenses. This involves instilling a flourescent dye into your tears - it's short-acting but means you'll need to keep your lenses out after the appointment, so do bring your glasses!</p>
                    <p>In situations where there are concerns about the fitting of the lens, we will have a discussion about re-fitting with a different lens if appropriate.</p>
                    <h2 id="lens-fit">Contact lens fitting</h2>
                    <p>Contact lens fitting is a process that's carried out over a few separate appointments so that appropriate sample lenses can be ordered after initial measurements have been taken and the lenses can then be trialled and checked as necessary.</p>
                    <Console variant="info">
                        <div style={{paddingBottom: 'var(--space-3)'}}>
                            <p>All types of lenses are fitted - both soft contact lenses and rigid gas permeable (RGP) / hard contact lenses. In addition, specialist lenses such as for keratoconus are also fitted.</p>
                            <Link to="#lensTypes" style={{fontSize: 'var(--type-xs)'}}>Find out more about the different types of lenses.</Link>
                        </div>
                    </Console>
                    <h3>Usage assessment & measurements</h3>
                    <p>At your initial appointment we will have a discussion about what you want to wear the lenses for so we can select the most appropriate option and I will take necessary measurements for selecting the best fitting contact lens. At this point I would need to order in some sample lenses, included in the fitting fee, for you to try at a follow-up appointment. </p>
                    <h3>First fitting & vision testing</h3>
                    <p>If you have never worn lenses before I will put the lenses in for you, however you're free to put them in yourself if you have worn lenses before. Once the lenses have settled (10 mins) I will check your vision and establish whether any refinements are needed to the prescription. I will then check that the lens is sitting well on your eye.</p>
                    <p>If this is your first time wearing contact lenses, you'll be able to go away for a few hours to try the lenses out in the real world before coming back in where I'll teach you how to insert and remove them, as well as clean them in the case of reusable lenses. For those who have worn lenses before, you'll be able to try them for a week.</p>
                    <h3>One week follow-up</h3>
                    <p>At the week follow-up, you will come in wearing the lenses and I will recheck your vision, make any further amendments to the prescription and the check the health of the front of the eye. With amended prescriptions, it may be necessary to carry out this one week follow-up again.</p>
                    <p>Once we're both happy with the fitting of the lens, I'll issue the prescription for you so that you can buy your lenses. Once you've bought your new lenses, I'd carry out aftercare appointments several months later to ensure everything is still as expected.</p>
                </Text>
            </section>
            <section id="lensTypes">
                <TxtImg
                    imgFirst={false}
                    split="thirdReverse"
                    imgSrc={data.eye}
                    imgAlt=""
                >
                    <h2>Soft lenses</h2>
                    <p>Soft contact lenses offer great flexibility. From daily disposable through to monthly extended wear, there is something for everyone. Daily disposables are a fantastic introduction to contact lenses as you can switch between them and your glasses as much or as little as you like.</p>
                    <p>There are lots of visual options available with soft lenses too; the most recent steps forward have been in multifocals and myopia control, both of which can be fitted at the practice.</p>
                    <p>Coloured contact lenses are also available, both with prescription and without. These lenses, always popular around Hallowe’en and the festive period, are considered ‘cosmetic’ but are still classed as a medical device like all contact lenses so must be fitted by a qualified optometrist or contact lens optician.</p>
                    <p>Unfortunately there are still fancy dress outlets which continue to illegally sell lenses and a year doesn’t go by without hearing a horror story caused by these. Rest assured I will happily fit these lenses and ensure that you have the safest lenses possible. If you have a particular requirement I can obtain custom-made lenses too (with particular colours etc.), so please <Link to="/contact">get in touch</Link>.</p>
                </TxtImg>
            </section>
            <section>
                <TxtImg
                    imgFirst={true}
                    split="third"
                    imgSrc={data.frame}
                    imgAlt=""
                >
                    <h2>Hard / RGP lenses</h2>
                    <p>Rigid Gas Permeable (RGP) lenses aren't catered for by every optometrist and are sometimes looked at as old fashioned lenses, but they are actually a fantastic alternative if people wear lenses most / all of the time.</p>
                    <p>They are a little plastic lens which floats on the tear film and after an initial adaptation they are very comfortable - a bit like getting a new pair of shoes! Because they float on the eye they offer superior vision and are much safer because there is a constant flow of tears underneath the lens. Hard lenses are more durable so can last upwards of 12 months and the rate of eye infections is dramatically less.</p>
                    <p>The lenses are tailor made for the patient and a new pair is machined every time we change the prescription. Hard lenses are also good for higher prescriptions - especially those who have been told they can't wear contact lenses with their prescription (generally those with astigmatism) and those with corneal issues such as a scar, a corneal graft / transplant or conditions such as keratoconus where conventional lenses won't fit.</p>
                </TxtImg>
            </section>
            <section style={{backgroundColor: 'var(--neutral-900)'}}>
                <TxtImg
                    imgFirst={false}
                    split="half"
                    imgSrc={data.glasses}
                    imgAlt="Traditional trial frame illustration"
                    removeShadow={true}
                >
                    <h2>Make an appointment</h2>
                    <p>To discuss contact lens options or to arrange an appointment, head to the contact page to get in touch.</p>
                    <Button to="/contact" external={false} type="primarySolid" margin="var(--space-5) 0 0 0">Get in touch</Button>
                </TxtImg>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        eye: file(relativePath: {eq: "eye.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    width: 800
                    formats: WEBP
                )
            }
        }
        frame: file(relativePath: {eq: "frame-test.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    width: 800
                    formats: WEBP
                )
            }
        }
        glasses: file(relativePath: {eq: "trial-frame.png"}) {
            childImageSharp {
                gatsbyImageData(
                    placeholder: BLURRED
                    width: 800
                    formats: WEBP
                )
            }
        }
    }
`

export default LensTypes